import React, { useContext, useEffect } from 'react';
import MenuAppBar from '../components/MenuAppBar';
import { Container, Typography } from '@mui/material';
import CompetitionRunForm from '../components/CompetitionRunForm';

const CompetitionRun = () => {
  
  return (
    <Container>
      <MenuAppBar title='Záznam letů' />
      <CompetitionRunForm />
    </Container>
  );
};

export default CompetitionRun;
