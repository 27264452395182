import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { startRegistration } from '@simplewebauthn/browser';

const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const optionsResponse = await axios.post('/api/webauthn/register', {email});
      const registrationResponse = await startRegistration(optionsResponse.data);

      await axios.post('/api/webauthn/register/verify', {
        email,
        response: registrationResponse,
      });

      setSuccess('Registrace úspěšná. Zkontrolujte svou e-mailovou schránku pro ověření e-mailu.');
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      setError(error.response?.data?.error || 'Nastala chyba při registraci.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Registrace
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="E-mail"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        
        <Button type="submit" variant="contained" color="primary">
          Registrovat
        </Button>
        <p><a href="/">Přihlásit se!</a></p>
      </form>
    </Container>
  );
};

export default RegisterForm;
