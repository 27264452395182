import React, { useState, useEffect } from 'react';

import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import axios from 'axios';
import { startRegistration } from '@simplewebauthn/browser';

const ValidateRegistrationPage = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  useEffect(() => {
    const handleLoad = async (e) => {
      // Perform actions after the component has fully loaded
      e.preventDefault();
      setError('');
      setSuccess('');

      try {
        
        

        const verifyResponse = await axios.post('/api/webauthn/verifyemail', {email, token});
        
        if (verifyResponse.data.success){
          setSuccess(verifyResponse.data.message || 'Validace úspěšná');
        } else {
          setError(verifyResponse.data.message || 'Validace neúspěšná');
        }
        
        //setTimeout(() => navigate('/'), 3000);
      } catch (error) {
        setError(error.response?.data?.error || 'Nastala chyba při validaci.');
      }
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Validace registovaného zařízení
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
    </Container>
  );
};

export default ValidateRegistrationPage;