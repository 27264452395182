//import * as React from 'react';
import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';



export default function MenuAppBar({title}) {
  
  const { logout, role } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElApp, setAnchorElApp] = React.useState(null);

  const open = null;
  const navigate = useNavigate();

  const handleAppMenu = (event) => {
    setAnchorElApp(event.currentTarget);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseAppMenu = () => {
    setAnchorElApp(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
    navigate('/');
  };

  const handleUserManagement = () => {
    setAnchorEl(null);
    navigate('/users');
  };

  const renderUserMenuItems = () => {
    switch (role) {
      case 'system_admin':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Můj účet</MenuItem>,
            <MenuItem onClick={handleUserManagement} key="appItem2">Uživatelé</MenuItem>
        ];
      case 'club_admin':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Profil</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem2">Můj účet</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem3">Club Dashboard</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem4">Member Management</MenuItem>,
        ];
      case 'user':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Profil</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem2">Můj účet</MenuItem>
        ];
      default:
        return null;
    }
  };
  const renderAppMenuItems = () => {
    switch (role) {
      case 'system_admin':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Kluby</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem2">Kategorie</MenuItem>
        ];
      case 'club_admin':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Kluby</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem2">Kategorie</MenuItem>
      ];
      case 'user':
        return [
            <MenuItem onClick={handleClose} key="appItem1">Kluby</MenuItem>,
            <MenuItem onClick={handleClose} key="appItem2">Kategorie</MenuItem>
      ];
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="app menu"
            aria-controls="menu-appbar"
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={handleAppMenu}
            color="inherit"
            
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElApp}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElApp)}
            onClose={handleCloseAppMenu}>
            {renderAppMenuItems()}
            </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {role && (<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {role}
          </Typography>)    
          }
          {role && (
            
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-userbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-userbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {renderUserMenuItems()}
                <MenuItem onClick={handleLogout}>Odhlásit se</MenuItem>
              </Menu>
              
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
