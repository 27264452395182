import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    role: null,
    isAuthenticated: false,
  });

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        try {
          const response = await axios.get('/api/auth/verifyToken', {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          setAuthState({
            ...authState,
            accessToken: accessToken,
            role: response.data.role,
            isAuthenticated: true,
          });
        } catch (error) {
          console.error('Token verification failed:', error);
          logout();
          
        }
      }
    };

    verifyToken();
  }, []);

  const login = (accessToken, /*refreshToken,*/ role) => {
    localStorage.setItem('accessToken', accessToken);
    //localStorage.setItem('refreshToken', refreshToken);
    setAuthState({
      accessToken,
      //refreshToken,
      role,
      isAuthenticated: true,
    });
  };

  const logout = async () => {
    //const refreshToken = localStorage.getItem('refreshToken');
    try {
      //const response = await axios.post('/api/auth/logOutToken', { token: refreshToken });
      
      localStorage.removeItem('accessToken');
      //localStorage.removeItem('refreshToken');
      setAuthState({
        accessToken: null,
        //refreshToken: null,
        role: null,
        isAuthenticated: false,
      });  
      
    } catch (error) {
      console.error('Logout failed:', error);
      logout();
    }

    
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
