import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Container, Typography, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../context/AuthContext';
import api from '../api';

const UserManagement = () => {
  const { role } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    if (role === 'system_admin') {
      api.get('/api/users')
        .then(response => setUsers(response.data))
        .catch(error => {
          setError(error.response?.data?.error || 'Nastala chyba při přihlášení.'); 
          console.error('Error fetching users:', error);
        });
    }
  }, [role]);

  const handleDelete = (id) => {
    api.delete(`/api/users/${id}`)
      .then(() => setUsers(users.filter(user => user.iduser !== id)))
      .catch(error => console.error('Error deleting user:', error));
  };

  if (role !== 'system_admin') {
    return (
      <Container>
        <Alert severity="error">Access Denied: You do not have permission to view this page.</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        User Management
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Button variant="contained" color="primary" component={Link} to="/users/create">
        Create User
      </Button>
      <List>
        {users.map(user => (
          <ListItem key={user.iduser}>
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={user.email}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" component={Link} to={`/users/edit/${user.iduser}`}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(user.iduser)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default UserManagement;
