import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, Paper, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TimePicker } from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Alert from '@mui/material/Alert';
import api from '../api';

const ItemTypes = {
  PILOT: 'pilot',
};

const CompetitionRunForm = () => {
  const [panels, setPanels] = useState([]);
  const [competition, setCompetition] = useState({});
  const [open, setOpen] = useState(false);
  const [currentPilot, setCurrentPilot] = useState(null);
  const [currentFlightIndex, setCurrentFlightIndex] = useState(null);
  const [formValues, setFormValues] = useState({ flightTime: '', flightAltitude: '', landingPoints: '' });
  const [error, setError] = useState('');

  const fetchCompetition = async () => {
    try {
      const response = await api.get('/api/competition?id=1');
      setCompetition(response.data);
      const panels = await createPanels(response.data);
      setPanels(panels);
    } catch (error) {
      console.error('Error fetching panels', error);
    }
  };

  useEffect(() => {
    fetchCompetition();
  }, []);

  const createPanels = async (data) => {
    if (!data || data.length === 0 || !data[0].CompetitionPilots) return [];
  
    const competitionPoints = await api.get('/api/competitionpoints');
    const competitionPoint = competitionPoints.data.competitionPoint;
  
    const newPilots = data[0].CompetitionPilots.map((pilot, i) => ({
      id: `pilot-${pilot.idcompetitionpilot}`,
      name: pilot.Pilot.pilotName,
      pilotTotalPoint: pilot.pilotTotalPoint,
      point: pilot.pilotCompetetionPoint,
      flights: Array.from({ length: data[0].competitionRounds }, (_, j) => ({
        id: `flight-${i + 1}-${j + 1}`,
        flightTime: pilot.CompetitionFlights[j]?.flightTime.replace("00:", '') || '',
        flightAltitude: pilot.CompetitionFlights[j]?.flightAltitude || '',
        landingPoints: pilot.CompetitionFlights[j]?.landingPoints || '',
        totalPoints: pilot.CompetitionFlights[j]?.totalPoints || '',
      })),
      CompetitionPilot: pilot,
    }));
  
    const newPanels = Array.from({ length: data[0].competitionStartingPoints }, (_, i) => ({
      id: competitionPoint[i],
      rows: Array.from({ length: 5 }, (_, j) => ({
        id: `row-${i}-${j + 1}`,
        pilots: [],
      })),
    }));
  
    newPilots.forEach(pilot => {
      const panel = newPanels.find(panel => panel.id === pilot.point) || { id: pilot.point, rows: Array.from({ length: 5 }, (_, j) => ({
        id: `row-${pilot.point}-${j + 1}`,
        pilots: [],
      })), };
      var row
      if (panel.id !== "0") {
        row = panel.rows[pilot.CompetitionPilot.pilotCompetetionPointPosition];
      } else {
        row = panel.rows.find(row => row.pilots.length < 1) || panel.rows[0];
      }
      row.pilots.push(pilot);
      if (!newPanels.includes(panel)) newPanels.push(panel);
    });
  
    return newPanels;
  };

  const handleClickOpen = (pilot, flightIndex) => {
    setCurrentPilot(pilot);
    setCurrentFlightIndex(flightIndex);
    setFormValues(pilot.flights[flightIndex]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const newPanels = [...panels];
    const panelIndex = newPanels.findIndex(panel => panel.rows.some(row => row.pilots.includes(currentPilot)));
    const rowIndex = newPanels[panelIndex].rows.findIndex(row => row.pilots.includes(currentPilot));
    const pilotIndex = newPanels[panelIndex].rows[rowIndex].pilots.findIndex(pilot => pilot === currentPilot);
    const savedPilot = newPanels[panelIndex].rows[rowIndex].pilots[pilotIndex]
    newPanels[panelIndex].rows[rowIndex].pilots[pilotIndex].flights[currentFlightIndex] = formValues;
    setPanels(newPanels);

    const competitionsflight = await api.post(`/api/competitionsflight/createcompetitionflight`,{idcompetitionpilot:savedPilot.CompetitionPilot.idcompetitionpilot,round:currentFlightIndex+1});
    const competitionsflightUpdate = await api.put(`/api/competitionsflight?id=${competitionsflight.data.idcompetitionflight}`, formValues);
    await fetchCompetition();

    setOpen(false);
  };
  
  const handleDelete = async () => {
    const flightRound = currentFlightIndex+1;
    const idcompetitionpilot = currentPilot.CompetitionPilot.idcompetitionpilot;

    const competitionsflightDelete = await api.delete(`/api/competitionsflight?idcompetitionpilot=${idcompetitionpilot}&round=${flightRound}`,);
    await fetchCompetition();

    setOpen(false);
  };

  const handleFormChange = (field, value) => {
    if (field === 'flightTime' && value.length == 1){
      value = '0'+value+':';
    }
    setFormValues({
      ...formValues,
      [field]: value
    });
  };

  const movePilot = async (sourcePanelId, sourceRowId, destPanelId, destRowId, pilotId) => {
    const sourcePanelIndex = panels.findIndex(panel => panel.id === sourcePanelId);
    const destPanelIndex = panels.findIndex(panel => panel.id === destPanelId);

    const sourcePanel = panels[sourcePanelIndex];
    const destPanel = panels[destPanelIndex];

    const sourceRowIndex = sourcePanel.rows.findIndex(row => row.id === sourceRowId);
    const destRowIndex = destPanel.rows.findIndex(row => row.id === destRowId);

    const sourceRow = sourcePanel.rows[sourceRowIndex];
    const destRow = destPanel.rows[destRowIndex];

    const pilotIndex = sourceRow.pilots.findIndex(pilot => pilot.id === pilotId);
    if (destRow.pilots.length==0){
      const [movedPilot] = sourceRow.pilots.splice(pilotIndex, 1);
      destRow.pilots.push(movedPilot);
      setPanels([...panels]);
      
      const competitionspilot = await api.put(`/api/competitionspilot?id=${movedPilot.CompetitionPilot.idcompetitionpilot}`, {pilotCompetetionPoint:destPanelId,pilotCompetetionPointPosition:destRowIndex});
    } else {
      setError('Nelze přesunout pilota na obsazené místo');
      setTimeout(() => {
        setError('');
      }, 2000);
    }

    
  };

  const Pilot = ({ pilot, panelId, rowId }) => {
    const [, ref] = useDrag({
      type: ItemTypes.PILOT,
      item: { id: pilot.id, panelId, rowId },
    });

    return (
      <div
        ref={ref}
        style={{
          padding: '8px',
          margin: '8px 0',
          backgroundColor: 'white',
          border: '1px solid lightgrey',
          borderRadius: '4px',
        }}
      >
        <Typography variant="subtitle1">{pilot.name} ({pilot.pilotTotalPoint})</Typography>
        {pilot.flights.map((flight, flightIndex) => (
          <Button
            key={flight.id}
            variant="contained"
            color={flight.flightTime ? "success" : "primary"}
            onClick={() => handleClickOpen(pilot, flightIndex)}
            style={{ margin: '4px 0px 0px 4px' }}
          >
            {flight.totalPoints||flightIndex + 1}
          </Button>
        ))}
      </div>
    );
  };

  const Row = ({ row, panelId }) => {
    const [, ref] = useDrop({
      accept: ItemTypes.PILOT,
      drop: (item) => movePilot(item.panelId, item.rowId, panelId, row.id, item.id),
    });

    return (
      <div ref={ref} style={{ minHeight: '100px', margin: '8px 0', border: '1px solid lightgrey', borderRadius: '4px', padding: '8px' }}>
        <Typography variant="subtitle2">{row.id.split('-')[2]}</Typography>
        {row.pilots.map((pilot, index) => (
          <Pilot key={pilot.id} pilot={pilot} panelId={panelId} rowId={row.id} />
        ))}
      </div>
    );
  };

  const Panel = ({ panel }) => {
    return (
      <Grid item xs={12} sm={6} key={panel.id}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">{panel.id}</Typography>
          {panel.rows.map((row) => (
            <Row key={row.id} row={row} panelId={panel.id} />
          ))}
        </Paper>
      </Grid>
    );
  };

  

  return (
    <Container>
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{
        marginBottom: 2,
        marginTop: 2,
      }}>Startoviště</Box> 
      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2}>
          {panels.map((panel) => (
            <Panel key={panel.id} panel={panel} />
          ))}
        </Grid>
      </DndProvider>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Zadejte údaje o letu {currentPilot && currentPilot.name} ({currentFlightIndex && currentFlightIndex+1})</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Čas (MM:SS)"
            format="mm:ss" 
            type="text"
            fullWidth
            value={formValues.flightTime}
            onChange={(e) => handleFormChange('flightTime', e.target.value)}
          />
           <TextField
            margin="dense"
            label="Výška"
            type="number"
            fullWidth
            value={formValues.flightAltitude}
            onChange={(e) => handleFormChange('flightAltitude', e.target.value)}
          />
          <TextField
            margin="dense"
            label="Body"
            type="number"
            fullWidth
            value={formValues.landingPoints}
            onChange={(e) => handleFormChange('landingPoints', e.target.value)}
          />
        </DialogContent>
        <DialogActions>
        {formValues.flightTime &&( <Button onClick={handleDelete} color="error">
            Smazat
          </Button>
        )}
          <Button onClick={handleClose} color="primary">
            Zrušit
          </Button>
          <Button onClick={handleSave} color="success">
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CompetitionRunForm;
