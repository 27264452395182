import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, Alert } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import api from '../api';

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { iduser, role } = useContext(AuthContext);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (id) {
      axios.get(`/api/users/${id}`)
        .then(response => setUser(response.data))
        .catch(error => console.error('Error fetching user:', error));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (role !== 'system_admin' && iduser !== parseInt(id)) {
      setError('You do not have permission to edit this user.');
      return;
    }

    if (id) {
      api.put(`/api/users/${id}`, user)
        .then(() => navigate('/users'))
        .catch(error => console.error('Error updating user:', error));
    }

    if (!id){
      api.post(`/api/users/createuser/`, user)
        .then(() => navigate('/users'))
        .catch(error => console.error('Error inserting user:', error));
      
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        {id ? 'Edit User' : 'Create User'}
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="First Name"
          name="firstName"
          value={user.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={user.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={user.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {role === 'system_admin' && (
          <TextField
            label="Role"
            name="role"
            value={user.role}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        )}
        <Button type="submit" variant="contained" color="primary">
          {id ? 'Update' : 'Create'}
        </Button>
      </Box>
    </Container>
  );
};

export default UserForm;
