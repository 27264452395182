import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { startAuthentication } from '@simplewebauthn/browser';
import MenuAppBar from '../components/MenuAppBar';
import { AuthContext } from '../context/AuthContext'; 

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(AuthContext); // Ujistěte se, že AuthContext je správně použit
  const navigate = useNavigate();

  
  useEffect(() => {
    document.title = 'Login page';
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const optionsResponse = await axios.post('/api/webauthn/login/options', { email });
      const authenticationResponse = await startAuthentication(optionsResponse.data);

      const verifyResponse = await axios.post('/api/webauthn/login/verify', {
        email,
        response: authenticationResponse,
      });

      login(verifyResponse.data.accessToken, /*verifyResponse.data.refreshToken,*/ verifyResponse.data.role);
      this.useContext(AuthContext);
      navigate('/dashboard');
    } catch (error) {
      setError(error.response?.data?.error || 'Nastala chyba při přihlášení.');
    }
  };

  return (
    <Container>
      <MenuAppBar title='Přihlášení' />
      <Container maxWidth="sm">
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="E-mail"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Přihlásit se
        </Button>
        <p>Nemáte registraci? <a href="/register">Zaregistrujte se!</a></p>
      </form>
    </Container>
    </Container>
    

  );
};

export default LoginForm;
