import { StrictMode } from 'react';
import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/LoginForm'; // Ujistěte se, že cesta je správná
import Register from './components/RegisterForm'; // Ujistěte se, že cesta je správná
import ValidateRegistrationPage from './components/ValidateRegistrationPage'; // Ujistěte se, že cesta je správná
import Dashboard from './pages/Dashboard'; // Ujistěte se, že cesta je správná
import UserManagement from './pages/UserManagement'; // Import stránky správy uživatelů
import UserForm from './components/UserForm'; // Import editačního formuláře uživatele
import CompetitionRun from './pages/CompetitionRun'; // Import formuláře pro zadání letů
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Root />} />
          <Route path="/competitionrun" element={<CompetitionRun />} />
          <Route path="/register" element={<Register />} />
          <Route path="/ValidateRegistration" element={<ValidateRegistrationPage />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/users" element={<PrivateRoute />}>
            <Route path="/users" element={<UserManagement />} />
            <Route path="/users/create" element={<UserForm />} />
            <Route path="/users/edit/:id" element={<UserForm />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
    
  );
};
const Root = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return <Login />;
};

export default App;
