import React, { useContext, useEffect } from 'react';
import { Container, Typography, Box, Button, Card, CardContent, CardActions, Grid, Item, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MenuAppBar from '../components/MenuAppBar';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Dashboard = () => {
  const { logout, role } = useContext(AuthContext);
  const navigate = useNavigate();

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </React.Fragment>
  );

  const handleLogout = () => {
    logout();
    navigate('/');
  };
  
  useEffect(() => {
    document.title = 'Dashboard';
  }, []);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  return (
    
    <Container>
      <MenuAppBar title='Dashboard' />
    
      <Typography variant="h4" component="h2" gutterBottom>
        Vítejte na dashboardu!
      </Typography>
      
      <Typography variant="body1">
        {role && `Vaše role: ${role}`}
      </Typography>
      
      
      <Grid container spacing={2}>
        <Grid size={4}>
          <Item><Card variant="outlined">{card}</Card></Item>
          
        </Grid>
        <Grid size={8}>
        <Item><Card variant="outlined">{card}</Card></Item>
        </Grid>
    
      </Grid>
      
      
    </Container>
  );
};

export default Dashboard;
